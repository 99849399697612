<template>
  <div id="addDeduction" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">TIPO DE DEDUCCIONES</v-row>
      <v-container fluid class="addCategory2">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" xs="6" class="marginCol">
            <v-row>
              <div class="form-group mb-5">
                <label for="idDedudction">Id Tipo de Deducción</label>
                <input
                  type="text"
                  name="idDedudction"
                  id="idDedudction"
                  placeholder="Id de Tipo de Deducción"
                  class="ml-n9 inputs"
                  autocomplete="false"
                  v-model="idDeduction"
                  required
                  maxlength="3"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-5">
                <label for="description" class="mr-12">Descripción</label>
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Descripción del orígen"
                  class="ml-6 inputs"
                  autocomplete="false"
                  v-model="description"
                  required
                  maxlength="100"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xs="6" align-self="center">
            <v-row justify="center">
              <button
                class="botonAmarillo"
                style="float: none"
                @click="validacion()"
              >
                Guardar
              </button>
              <router-link to="/deductions" id="backDeduction">
                <button class="botonAmarilloDerecho">Regresar</button>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Tipo de Deducción</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar el tipo de deducción?
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Tipo de deducción</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
import Loader from "@/components/Loader";
export default {
  components: {
    Sidebar,
    Sesion,
    Loader,
  },
  data() {
    return {
      search: "",
      show: false,
      expiration: false,
      dialog: false,
      description: "",
      idDeduction: "",
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
    };
  },
  methods: {
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/deducciones",
          {
            ClaveCFDI: this.idDeduction,
            TipoDeduccion: this.description,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.idDeduction = "";
          this.description = "";
          this.respuesta = "El tipo de deducción fue guardado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.idDeduction = "";
            this.description = "";
            this.respuesta = "El tipo de deducción no fue almacenada";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    validacion: function () {
      this.errors = [];
      if (
        this.idDeduction == " " ||
        this.idDeduction == 0 ||
        this.idDeduction == ""
      ) {
        this.errors.push("Se debe especificar el id del tipo de deducción.");
      }
      if (this.description == "" || this.description == " ") {
        this.errors.push(
          "Se debe especificar la descripción del tipo de deducción."
        );
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    cancel() {
      this.idDeduction = "";
      this.description = "";
      this.advice = false;
    },
    aux() {
      this.confirmation = false;
      this.idDeduction = "";
      this.description = "";
      this.$router.push("/deductions");
    },
  },
  created() {},
};
</script>